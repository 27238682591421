// Imatges
import imgDemocrata from '../assets/img/harris.png'
import imgRepublica from '../assets/img/trump.png'

const Grafic = ({ majoria, textos, votsHarris, votsTrump, esEnquesta }) => {
	const total = (majoria - 1) * 2

	return (
		<div className='grafic'>
			{!esEnquesta && (
				<img
					src={imgDemocrata}
					alt={textos.candidat_democrata}
					width={100}
					height={100}
				/>
			)}
			<div>
				<div className='candidats e9'>
					<span>{textos.candidat_democrata}</span>
					<span>{textos.candidat_republica}</span>
				</div>
				<div className='barra'>
					<div
						className={esEnquesta ? 'enquesta' : ''}
						style={{ width: `${(votsHarris * 100) / total}%` }}
					></div>
					<div
						className={esEnquesta ? 'enquesta' : ''}
						style={{ width: `${(votsTrump * 100) / total}%` }}
					></div>
				</div>
				<div className='dades'>
					<p>
						<span className='e11'>{votsHarris}</span>
						<span className='e10'>{textos.delegats}</span>
					</p>
					<p>
						<span className='e11'>{majoria}</span>
						<span className='e10'>{textos.majoria}</span>
					</p>
					<p>
						<span className='e11'>{votsTrump}</span>
						<span className='e10'>{textos.delegats}</span>
					</p>
				</div>
			</div>
			{!esEnquesta && (
				<img
					src={imgRepublica}
					alt={textos.candidat_republica}
					width={100}
					height={100}
				/>
			)}
		</div>
	)
}
export default Grafic
