import { createRoot } from 'react-dom/client'
import { App } from './App'

document.addEventListener('DOMContentLoaded', function () {
	const container = document.getElementById('app-eleccions-eua-2024')

	const lang = document.documentElement.lang || 'ca'
	const titol = container.dataset.titol || null
	const esEnquesta = container.dataset.esenquesta || false
	const votsHarris = container.dataset.harris || 0
	const votsTrump = container.dataset.trump || 0
	const escrutini = container.dataset.escrutini || null
	const majoria = container.dataset.majoria || 270
	const link =
		container.dataset.link ||
		'https://www.ara.cat/tema/eleccions-als-estats-units/'

	const root = createRoot(container)
	root.render(
		<App
			lang={lang}
			titol={titol}
			esEnquesta={esEnquesta}
			votsHarris={votsHarris}
			votsTrump={votsTrump}
			escrutini={escrutini}
			majoria={majoria}
			link={link}
		/>
	)
})
