// Estils
import './style.css'
// Textos
import diccionari from './assets/textos.json'
// Components
import Header from './components/header'
import Grafic from './components/grafic'

export function App({
	lang,
	titol,
	esEnquesta,
	votsHarris,
	votsTrump,
	escrutini,
	majoria,
	link,
}) {
	const textos = diccionari[lang]

	return (
		<>
			<Header
				link={link}
				titol={titol}
				textos={textos}
				esEnquesta={esEnquesta}
			/>

			<div className='escrutini e10'>
				{console.log(isNaN(parseInt(escrutini)))}
				{escrutini && (
					<>
						{isNaN(parseInt(escrutini)) ? (
							<>{escrutini}</>
						) : (
							<>
								{textos.escrutini} <span className='e11'>{escrutini}%</span>
							</>
						)}
					</>
				)}
			</div>

			<Grafic
				majoria={majoria}
				textos={textos}
				votsHarris={votsHarris}
				votsTrump={votsTrump}
				esEnquesta={esEnquesta}
			/>
		</>
	)
}
