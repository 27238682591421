import logoEleccions from '../assets/img/eleccions_eua.svg'

const Header = ({ link, titol, esEnquesta, textos }) => {
	return (
		<header>
			<a href={link}>
				<img
					src={logoEleccions}
					alt={titol || textos.titol}
					width={16}
					height={16}
				/>
				<h3>
					<span className='e9'>
						{titol || esEnquesta ? textos.titol_enquesta : textos.titol}
					</span>{' '}
					<span className='e8'>2024</span>
				</h3>
			</a>
			<a href={link} className='t7'>
				<span className='desktop'>{textos.mes_dades}</span>
				<span className='mobile'>{textos.mes}</span>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='12'
					height='14'
					viewBox='0 0 12 14'
					fill='none'
				>
					<path
						d='M7.00016 3.33398L6.17766 4.15648L9.43266 7.41732H2.3335V8.58398H9.43266L6.17766 11.8448L7.00016 12.6673L11.6668 8.00065L7.00016 3.33398Z'
						fill='#0076AB'
					/>
				</svg>
			</a>
		</header>
	)
}
export default Header
